<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card shadow-sm">
        <div class="card-body">
          <h3 class="mb-5 text-primary">Orders Management Panel</h3>

          <!-- Success or Error Message -->
          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">
            {{ message }}
          </div>

          <!-- Search Filter Section -->
          <div class="search-section mb-4">
            <label for="search" class="form-label">Search Orders:</label>
            <div class="input-group mb-3">
              <input
                  type="text"
                  v-model="filters.search"
                  id="search"
                  class="form-control"
                  placeholder="Search by phone or name"
              />
            </div>
            <label for="fromDate" class="form-label">From Date:</label>
            <div class="input-group mb-3">
              <input
                  type="date"
                  v-model="filters.from_date"
                  id="fromDate"
                  class="form-control"
              />
            </div>
            <label for="toDate" class="form-label">To Date:</label>
            <div class="input-group mb-3">
              <input
                  type="date"
                  v-model="filters.to_date"
                  id="toDate"
                  class="form-control"
              />
            </div>
            <button @click="applySearchFilter" class="btn btn-primary" :disabled="!isSearchValid">
              Apply Filter
            </button>
            <button @click="exportToCSV" class="btn btn-success ml-3">
              Export as CSV
            </button>
          </div>

          <!-- Orders Table -->
          <div v-if="filteredOrders.length > 0" class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead class="table-dark">
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Phone</th>
                <th>Created at</th>
                <th>Address</th>
                <th>Location</th>
                <th>Barcode</th>
                <th>Total</th>
                <th>مرتجع</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="order in filteredOrders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                <td>{{ order.phone }}</td>
                <td>{{ order.created_at }}</td>
                <td>{{ order.address }}</td>
                <td>{{ order.governorates_name }} - {{ order.city_name }} - {{ order.area_name }}</td>
                <td v-if="order.invoice_barcode">{{ order.invoice_barcode }}</td>
                <td v-else>-</td>
                <td>{{ order.total.toFixed(2) }}</td>
                <td v-if="order.status == 10">مرتجع</td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- Message when no orders are available -->
          <div v-else-if="allOrder.length === 0 && hasFiltered" class="alert alert-warning text-center">
            No orders found for the provided search criteria.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: 'OrderTracking',

  data() {
    return {
      allOrder: [],
      filters: {
        search: '',
        from_date: '',
        to_date: ''
      },
      message: '',
      isSuccess: true,
      hasFiltered: false,
    };
  },

  computed: {
    // Filter orders based on search, from_date, and to_date
    filteredOrders() {
      let filtered = this.allOrder;

      if (this.filters.search.trim()) {
        filtered = filtered.filter(order =>
            order.phone.includes(this.filters.search) ||
            `${order.first_name} ${order.middle_name} ${order.last_name}`.toLowerCase().includes(this.filters.search.toLowerCase())
        );
      }

      if (this.filters.from_date) {
        filtered = filtered.filter(order =>
            new Date(order.created_at) >= new Date(this.filters.from_date)
        );
      }

      if (this.filters.to_date) {
        filtered = filtered.filter(order =>
            new Date(order.created_at) <= new Date(this.filters.to_date)
        );
      }

      return filtered;
    },
    // Ensure that at least one filter is valid before enabling search
    isSearchValid() {
      return this.filters.search.trim().length > 0 || this.filters.from_date || this.filters.to_date;
    }
  },

  methods: {
    // Load all orders initially
    orderLoad() {
      axiosInstance.get('api/order-export-management-not-completed')
          .then(({ data }) => {
            this.allOrder = data;
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

    // Apply search filter
    applySearchFilter() {
      this.hasFiltered = true;
    },

    // Export filtered orders to CSV
    exportToCSV() {
      const headers = [
        'ID', 'User Name', 'Phone', 'Created At', 'Address', 'Location', 'Total', 'مرتجع'
      ];

      // Helper function to escape commas and wrap fields in double quotes
      const escapeField = (field) => {
        if (field === null || field === undefined) return '';
        return `"${String(field).replace(/"/g, '""')}"`;
      };

      const rows = this.filteredOrders.map(order => [
        escapeField(order.id),
        escapeField(`${order.first_name} ${order.middle_name} ${order.last_name}`),
        escapeField(order.phone),
        escapeField(order.created_at),
        escapeField(order.address),  // Ensuring the address is wrapped in quotes
        escapeField(`${order.governorates_name} - ${order.city_name} - ${order.area_name}`),
        escapeField(order.total),
        escapeField(order.status === 10 ? 'مرتجع' : '')  // Add an empty string or another default value for other statuses
      ]);

      // Combine headers and rows to create CSV data
      const csvContent = [
        headers.map(escapeField).join(','), // Escape headers
        ...rows.map(row => row.join(','))   // Escape each row
      ].join('\n');

      // Add BOM for proper UTF-8 encoding (for Arabic characters)
      const bom = '\uFEFF'; // BOM character
      const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });

      // Trigger download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'orders.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  },

  mounted() {
    this.orderLoad(); // Load all orders initially
  }
};
</script>
<style scoped>
/* General Panel Styling */
.main-panel {
  background-color: #f8f9fa;
  padding: 20px;
}

.card {
  border-radius: 10px;
}

.card-body {
  padding: 20px;
}

h3 {
  font-weight: bold;
}

/* Search Section Styling */
.search-section {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
}

.input-group .form-control {
  flex: 1;
}

.input-group button {
  margin-left: 10px;
}

/* Table Styling */
.table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.table th, .table td {
  padding: 15px;
  text-align: left;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #ddd;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-dark {
  background-color: #343a40;
  color: white;
}

.alert {
  margin-top: 20px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .table {
    font-size: 12px;
  }
}
</style>
