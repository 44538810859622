<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Location</th>
              <th>Address</th>
              <th>Barcode</th>
              <th>Date</th>
              <th>Out Warehouse</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in allOrder" :key="order.id">
              <th scope="row">{{ order.id }}</th>
              <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
              <td>{{ order.governorates_name }} {{ order.city_name }} {{ order.area_name }}</td>
              <td>{{ order.address }}</td>
              <td>{{ order.invoice_barcode }}</td>
              <td>{{ order.created_at }}</td>
              <td>
                <label class="switch">
                  <input type="checkbox" v-model="order.out_warehouse"
                         @change="toggleOutWarehouse(order.id, order.out_warehouse)">
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      allOrder: [],
    };
  },

  created() {
    this.orderLoad(); // Load orders from the server
  },

  methods: {
    orderLoad() {
      axiosInstance.get(`api/aramex-delivery-invoices-completed/${this.$route.params.id}`)
          .then(({ data }) => {
            // Ensure that the out_warehouse field is correctly set for each order
            this.allOrder = data.map(order => ({
              ...order,
              out_warehouse: order.out_warehouse === 1
            }));
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

    toggleOutWarehouse(orderId, outWarehouse) {
      axiosInstance.post(`api/toggle-out-warehouse`, {
        id: orderId,
        out_warehouse: outWarehouse
      }).then(() => {
        this.message = `Order #${orderId} updated successfully!`;
        this.isSuccess = true;
      }).catch(error => {
        console.error('Error toggling out_warehouse:', error);
        this.message = error.response?.data?.error || `Failed to update order #${orderId}.`;
        this.isSuccess = false;
      });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
