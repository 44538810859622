<template>
  <!-- ======================== Navigation ======================== -->

  <a href="https://wa.link/rdrqlg" target="_blank" class="wtsp">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="70" height="70" viewBox="0 0 48 48">
      <path fill="#fff"
        d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z">
      </path>
      <path fill="#fff"
        d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z">
      </path>
      <path fill="#cfd8dc"
        d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z">
      </path>
      <path fill="#40c351"
        d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z">
      </path>
      <path fill="#fff" fill-rule="evenodd"
        d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z"
        clip-rule="evenodd"></path>
    </svg>
  </a>

  <!-- <div class="lamp-container">
    <img id="lamp" src="https://api.gocami.com/lamp/Artboard1.png" width="130" height="130" />
    <img id="lamp-hover" src="https://api.gocami.com/lamp/Artboard2.png" @mouseover="showAnimation = true"
      @mouseleave="showAnimation = false" width="130" height="130" />
  </div> -->

  <!-- <img id="lamp" src="https://api.gocami.com/lamp/off.png" class="lamp-off"> -->

  <!-- <div class="lamp-container">
    <img id="lamp" @mouseover="showAnimation = true" @mouseleave="showAnimation = false"
      src="https://api.gocami.com/lamp/on.png" class="lamp-on">
  </div>
-->

  <!-- <div v-if="showAnimation" class="animated-data" :class="{ show: showAnimation }">
    <transition name="tip-fade">
      <div class="speech-bubble" v-if="activeTipIndex !== -1">
        <p>Did you know that <span
            style="font-weight: bold !important; font-size: large !important; font-style: italic; text-decoration: underline !important;">{{
              tips[activeTipIndex].brand_name }}</span></p>
        <p v-html="tips[activeTipIndex].tips_text"></p>
      </div>
    </transition>
  </div> -->

  <!-- <div id="top-header" class="justify-content-center text-center bg-light p-2"
    style="letter-spacing: 1px; text-transform: uppercase; max-height: 27px !important; color: #194944">
    <marquee direction="right"><span class="fw-bolder">Free delivery</span> on orders over $25</marquee>
  </div> -->

  <nav class="navbar navbar-expand-lg pb-0 mb-0 d-flex top-nav" style="background-color: #EDEFF3;">
    <div class="container container-fluid d-flex" style="justify-content: center !important;">
      <!-- Logo on the left -->
      <router-link to="/" class="logo-icon navbar-brand">
        <img :src="'https://api.gocami.com/logo_image/' + logos.logo" alt="Gocami" />
      </router-link>

      <form class="search-form">
        <input v-model="keyword" @input="handleInput" @keyup.enter="navigateToSearchResults" type="search"
          class="form-control" placeholder="Search" aria-label="Search" id="search-input"
          style="border-radius: 50px !important" autocomplete="off">
      </form>

      <!-- User with favorite and cart on the right -->
      <div class="d-flex">
        <!-- User icon or profile name -->
        <span class="navbar-text user me-2 d-flex user-profile" v-if="this.user?.id" @click="toggleUser">
          <span class="text-white fs-5" style="color:#154068 !important">{{ publicKey }} </span>
          <i class="pi pi-chevron-down text-white ps-1 fs-5" style="color:#154068 !important"></i>
        </span>

        <span class="navbar-text user me-2" v-else>
          <router-link to="/login">
            <i class="pi pi-user text-white" style="color:#154068 !important"></i>
          </router-link>
        </span>

        <div class="user-nav" :class="{ 'show': isToggleUser }">
          <div>

            <router-link to="/user-profile">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-user pe-2"></i>My Profile</p>
            </router-link>

            <router-link v-if="role === 1" to="/admin">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Admin Panel</p>
            </router-link>
            <router-link v-else-if="role === 2" to="/prodadmin">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 3" to="/prod-admin">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 4" to="/affiliate">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 5" to="/sub-affiliate">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 6" to="/super-affiliate">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 7" to="/data-entry">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 8" to="/order-management">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 9" to="/admin-export-results">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 20" to="/admin-export-results">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 10" to="/super-affiliate">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 11" to="/store-panel">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 12" to="/product-stock">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 13" to="/superadmin">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 25" to="/admin-jarde">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 26" to="/description-jarde">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 27" to="/design-jarde">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 28" to="/final-jarde">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 30" to="/products-new">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 50" to="/target-change">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 51" to="/inserted-data">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 52" to="/accounting-form">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 60" to="/diffuser-form">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>
            <router-link v-else-if="role === 70" to="/quantity-check">
              <p class="border-bottom ps-2 mt-3 py-3"><i class="pi pi-home pe-2"></i>Dashboard</p>
            </router-link>

            <router-link to="/login" @click="logout">
              <p class="ps-2 mt-3 py-1 mb-1"><i class="pi pi-sign-out pe-2"></i>Logout</p>
            </router-link>
          </div>
        </div>

        <!-- Favorite icon -->
        <span class="navbar-text favNav position-relative me-2" @click="toggleRightNavFav">
          <i class="pi pi-heart text-white" style="color:#154068 !important"></i>
          <span class="badge">{{ getfav }}</span>
        </span>

        <!-- <div v-if="this.user?.id"> -->
        <div>
          <router-link to="/addtocart-view">
            <span class="navbar-text shopping-bag position-relative ms-2 d-flex">
              {{ getcart }} ITEM(s) - {{ getprice.length > 0 ? getprice[0].toFixed(2) : '0.00' }}$ <i
                class="pi pi-shopping-bag ms-2 mb-1"></i>
            </span>
          </router-link>
        </div>
        <!-- Shopping bag icon with red badge -->


        <!-- <div v-else>
          <router-link to="/login">
            <span class="navbar-text shopping-bag position-relative ms-2 d-flex" style="border:1px solid #154068; color:#154068">
              {{ getcart }} ITEM(s) - {{ getprice.length > 0 ? getprice[0].toFixed(2) : '0.00' }}$ <i
                class="pi pi-shopping-bag ms-2 mb-1"></i>
            </span>
          </router-link>
        </div> -->

      </div>
    </div>
  </nav>

  <div class="container text-center justify-content-center m-auto">
    <div class="d-flex align-items-center justify-content-center" v-if="shouldDisplayResults">
      <div class="search-results row justify-content-center">
        <div class="col-md-6 mb-3" v-for="result in searches" :key="result.id">
          <div class="card">
            <router-link :to="{
          name: 'ProductDetail',
          params: { id: result.product_id },
        }" class="mfp-open" @click="productClicked(result.id)">
              <div class="card-body">
                <img :src="'https://api.gocami.com/product_detail/' + result.image" width="75" height="75" />{{
          result.title }}
                <br />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="no-results text-center p-0 m-0"></div>
  </div>

  <!-- Cart Nav Start -->
  <div class="right-nav" :class="{ 'show': isRightNavOpen }">
    <!-- Your right-side navigation content goes here -->
    <div @click="toggleRightNav">
      <span class="fs-4 p-3 close text-black border border-dark" style="border-radius: 50px !important;">&#10005;</span>
    </div>

    <div>
      <p class="fw-bolder" style="margin-top: 65px !important; font-size: 14pt !important;">Shopping Cart</p>
    </div>

    <div>

      <div v-if="this.user?.id">

        <div v-if="carts.length && carts.length < 1">
          <p style="margin-top: 65px !important; font-size: 10pt !important;">Your cart is empty!</p>
        </div>
        <div v-else>
          <div v-for="carts in carts" :key="carts.id" class="mt-5 d-flex">
            <img :src="'https://api.gocami.com/product_detail/' + carts.productdetail.image" width="75" height="75"
              alt="" />
            <div class="mt-4 ms-2">
              <span class="fw-bolder">{{ carts.productdetail.sku }} - {{ carts.productdetail.title }}</span>
              <br />
              <span class="d-flex mt-2">{{ carts.quantity }} x <p>${{ carts.sale_price.toFixed(2) }}</p></span>
            </div>

            <div class="ms-3 mt-4" @click="remove(carts)"> <i class=" pi pi-trash text-danger trash"></i></div>

          </div>

          <!-- <div class="mt-5 mb-5 fs-3 text-dark fw-bolder">Subtotal: </div> -->

          <div class="d-flex mb-5 text-center justify-content-center">

            <router-link to="/addtocart-view"><button class="button-cart mx-5 p-3 bg-dark border-0 text-white"
                style="border-radius: 50px !important;">VIEW CART</button></router-link>

            <!-- <button class="button-checkout mx-5 p-3 border-0 text-white bg-dark"
              style="border-radius: 50px !important;">VIEW CHECKOUT</button> -->
          </div>
        </div>

      </div>

      <div v-else></div>

    </div>

  </div>

  <!-- Cart Nav End -->
  <!-- Favorite Nav Start -->

  <div class="right-nav" :class="{ 'show': isRightNavFavOpen }">
    <!-- Your right-side navigation content goes here -->
    <div @click="toggleRightNavFav">
      <span class="fs-4 p-3 close text-black border border-dark" style="border-radius: 50px !important;">&#10005;</span>
    </div>

    <div>
      <p class="fw-bolder" style="margin-top: 65px !important; font-size: 14pt !important;">Favorite</p>
    </div>

    <div>
      <p style="margin-top: 65px !important; font-size: 10pt !important;">Your favorite list is empty!</p>
    </div>


    <div>

      <div v-if="this.user?.id">

        <div v-if="favorite.length && favorite.length < 1">
          <p style="margin-top: 65px !important; font-size: 10pt !important;">Your favorite list is empty!</p>
        </div>
        <div v-else>
          <div v-for="favorite in favorite" :key="favorite.id" class="mt-5 d-flex">
            <img :src="'https://api.gocami.com/product_detail/' + favorite.productdetail.image" width="75" height="75"
              alt="" />
            <div class="mt-4 ms-2">
              <span class="fw-bolder">{{ favorite.productdetail.sku }} - {{ favorite.productdetail.title }}</span>
              <br />
            </div>

            <div class="ms-3 mt-4" @click="removeFav(favorite)"><i class=" pi pi-trash text-danger trash"></i></div>

          </div>

          <div class="d-flex mb-5 text-center justify-content-center mt-5">

            <router-link to="/addtofavorite-view"> <button class="button-cart mx-5 p-3 border-0 text-white bg-dark"
                style="border-radius: 50px !important;">FAVORITE LIST</button></router-link>

          </div>

        </div>

      </div>

      <div v-else></div>

    </div>
  </div>

  <!-- Favorite Nav End -->

  <nav v-if="!isPC" class="shadow navbar navbar-expand-lg pt-0 mt-0"
    style="background-color:#154068 !important; overflow-x: auto !important; z-index: 1 !important;">
    <div class="container-fluid mx-2">

      <div class="d-flex">

        <!-- <span class="ms-5">ALL CATEGORIES</span> -->

        <span class="left-side mx-3 mt-4 cat-name" v-for="category in categories" :key="category.id">
          <router-link :to="{ name: 'Categories', params: { id: category.category_name } }"
            style="text-transform: uppercase !important; color:white !important; font-size: 9pt !important;">
            {{ category.category_name }}
          </router-link>
        </span>
      </div>
    </div>
  </nav>

  <nav class="shadow navbar navbar-expand-lg pt-0 mt-0 category-nav" style="background-color:#154068 !important">
    <div class="container-fluid mx-5">

      <div class="d-flex">
        <!-- <span class="ms-5">ALL CATEGORIES</span> -->

        <span class="left-side mx-5 mt-2 cat-name" v-for="category in categories" :key="category.id">
          <router-link :to="{ name: 'Categories', params: { id: category.category_name } }"
            style="text-transform: uppercase !important; color:white; font-size: 9pt !important;">
            {{ category.category_name }}
            <i class="pi pi-chevron-down" style="font-size:10px !important; padding-left:4px !important"></i>
          </router-link>

          <div class="category-hover-box">
            <div class="row">
              <div class="col-5">
                <div v-for="sub in category.subs" :key="sub.id" class="sub-container">
                  <router-link :to="{ name: 'SubCategory', params: { id: sub.sub_category_name } }">
                    <p class="sub_name mt-2 ms-4" style="max-width: 300px !important;">
                      <span class="me-3">
                        <img :src="'https://api.gocami.com/sub_category_image/' + sub.sub_category_image" width="50"
                          height="50" />
                      </span>
                      {{ sub.sub_category_name }}
                    </p>
                  </router-link>
                </div>
              </div>

              <div class="col-2">
                <div class="vl"></div>
              </div>

              <div class="col-5">
                <div class="row me-5">
                  <div class="col-4 mt-5 d-flex border-right" v-for="prodc in category.prodc" :key="prodc.id">
                    <article style="overflow: hidden">
                      <div class="figure-grid">

                        <div class="image">
                          <router-link :to="{
          name: 'ProductDetail',
          params: { id: prodc.id },
        }" class="mfp-open">
                            <img :src="'https://api.gocami.com/product_main/' +
          prodc.image
          " alt="prodc.product_name" class="product-image" />
                          </router-link>
                        </div>

                        <div class="text text-start ms-3">
                          <h2 class="title h5 pb-0 mb-0" style="color: black">{{ prodc.product_name }}</h2>
                        </div>

                      </div>
                    </article>
                  </div>

                  <div class="row">
                    <router-link :to="{ name: 'Categories', params: { id: category.category_name } }">
                      <div class="text-center justify-content-center mt-5 see-more">See More</div>
                    </router-link>

                  </div>
                </div>
              </div>
            </div>
            <!-- Content of the hover box goes here -->
            <!-- Vertical Line -->
          </div>
        </span>
      </div>
    </div>
  </nav>

  <!-- ========================  Header content ======================== -->
</template>

<script scoped>
import axiosInstance from "../../../../axiosInstance";

import router from "@/router";
import { useUserStore } from "../../../../store";
import Cookies from "js-cookie";

// const clearLocalStorage = () => {
//   localStorage.clear();
//   router.push("/");
// };

/*const timeoutDuration = 3600000;
var timeout = setTimeout(clearLocalStorage, timeoutDuration);

document.addEventListener("mousemove", () => {
  clearTimeout(timeout);
  timeout = setTimeout(clearLocalStorage, timeoutDuration);
});*/

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      isPC: false,
      clickCount: 0,
      prodc: [],
      subs: [],
      getcart: [],
      getprice: [], // Initialize as an empty array
      getfav: [],
      carts: [],
      isRightNavOpen: false,
      isRightNavFavOpen: false,
      isToggleUser: false,
      showAnimation: false,
      isDropdownOpenTop: false,
      userDetails: null,
      favorites: {
        product_id: "",
        user_id: "",
      },
      favorite: [],
      form: {
        first_name: "",
        last_name: "",
        email: "",
        roles: "0",
        password: "",
        date_of_birth: "",
      },
      tips: [],
      activeTipIndex: -1,
      shuffledTips: [],
      tipTimer: null,
      errors: null,
      showLoginForm: true,
      showRegisterForm: false,
      countcart: 0,
      showInfoBox: false,
      showInfoMobileBox: false,
      keyword: "",
      searches: "",
      menuVisible: false,
      categories: [],
      logos: [],
      countFav: 0,
      showSidebar: false,
      childcategories: [],
      loading: true,
      publicKey: this.user?.first_name.concat(" ", this.user?.last_name),
      loginSuccess: false, // Control the visibility of the success message
      showSuccessMessage: false,
      login: {
        email: "",
        password: "",
      },
      validationErrors: {},
    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.loginSuccess) {
      next((vm) => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  created() {
    this.categoryLoad();
    this.cartLoad();
    this.getCart();
    this.getFav();
    // setInterval(this.countLoad, 4000); // Update every 60 seconds (adjust as needed)
  },

  mounted() {
    this.fetchData();
    // this.fetchUserDetails();
    this.checkIfPC();
    window.addEventListener("resize", this.checkIfPC);
  },

  watch: {
    keyword() {
      this.search();
    },
  },


  methods: {

    checkIfPC() {
      this.isPC = window.innerWidth >= 768;
    },

    productClicked() {
      // Logic to handle product click
      // For example, you can update a property to hide search-results
      this.searches = []; // Clear the search results when a product is clicked
    },

    handleInput() {
      if (this.keyword.trim() === '') {
        this.searches = []; // Clear the search results when the input is empty
      }
    },
    navigateToSearchResults() {
      if (this.keyword.trim() !== '') {
        // Navigate to another page passing the search keyword as a query parameter
        window.location.href = `/products-search?keyword=${encodeURIComponent(this.keyword)}`;
      }
    },

    remove(carts) {
      var url = `api/cart-delete/${carts.id}`;
      axiosInstance
        .delete(url)
        .then((response) => {
          if (Array.isArray(this.carts) && carts) {
            const index = this.carts.indexOf(carts);
            if (index !== -1) {
              this.carts.splice(index, 1);
            }
          }
          if (response.status === 200) {
            this.message = "Product has been removed from cart successfully"; // Update success message
            this.isError = false; // Reset isError to false
          } else {
            this.message = "Failed to remove product"; // Update error message
            this.isError = true; // Set isError to true
          }
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        })
        .catch((error) => {
          this.message = "Failed to remove product"; // Update error message
          this.isError = true; // Set isError to true
          console.error("Failed to remove product", error);
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        });
    },

    removeFav(favorite) {
      var url = `api/product-detail-favorite-delete/${favorite.id}`;
      axiosInstance
        .delete(url)
        .then((response) => {
          if (Array.isArray(this.favorite) && favorite) {
            const index = this.favorite.indexOf(favorite);
            if (index !== -1) {
              this.favorite.splice(index, 1);
            }
          }
          if (response.status === 200) {
            this.message = "Product has been removed from cart successfully"; // Update success message
            this.isError = false; // Reset isError to false
          } else {
            this.message = "Failed to remove product"; // Update error message
            this.isError = true; // Set isError to true
          }
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        })
        .catch((error) => {
          this.message = "Failed to remove product"; // Update error message
          this.isError = true; // Set isError to true
          console.error("Failed to remove product", error);
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        });
    },

    getCart() {
      var page = `api/get-cart-count?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.getcart = data.count;
        this.getprice = [data.total]; // Wrap the total in an array
      });
    },

    getFav() {
      var page = `api/get-fav-count?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.getfav = data.count;
        this.favorite = data.favorite;
      });
    },

    toggleUser() {
      this.isToggleUser = !this.isToggleUser;
      if (!this.isToggleUser) {
        this.isToggleUser = false;
      }
    },

    toggleRightNav() {
      this.isRightNavOpen = !this.isRightNavOpen;
      this.isRightNavFavOpen = false; // Close the Favorite Navigation
    },

    toggleRightNavFav() {
      this.isRightNavFavOpen = !this.isRightNavFavOpen;
      this.isRightNavOpen = false; // Close the Cart Navigation
    },


    RegisterForm() {
      axiosInstance
        .post(`api/register`, this.form)
        .then(() => {
          router.push({ path: "/", query: { registrationSuccess: true } });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            // Validation error
            this.errors = error.response.data.errors;
          } else {
            // Other types of errors
            this.errors = {
              general: "An error occurred while processing your request.",
            };
          }
        });
    },

    toggleDropdownTop() {
      this.isDropdownOpenTop = !this.isDropdownOpenTop;
    },

    // fetchUserDetails() {
    //   axiosInstance
    //     .get(`api/auth/facebook/callback?code=${this.userId}`)
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       // Handle the Facebook login error
    //       console.error(error.response.data);
    //     });
    // },
    closeMenu() {
      this.menuVisible = false; // Close the hamburger menu by setting menuVisible to false
    },

    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
      this.showRegisterForm = !this.showRegisterForm;
    },

    save() {
      this.saveData();
    },

    saveData() {
      axiosInstance
        .post(`api/login`, this.login)
        .then((response) => {
          Cookies.set("token", response.data.authorisation.token);
          const userStore = useUserStore();
          userStore.setUser(response.data.user);

          const from = this.$route.query.from;

          // Update the user data in the local component state
          this.user = response.data.user;
          this.publicKey = this.user?.first_name.concat(
            " ",
            this.user?.last_name
          );
          this.showLoginForm = false;
          if (from) {
            router.push(from);
          } else {
            router.push({ path: "/" });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
    },

    loginWithFacebook() {
      axiosInstance
        .get("api/auth/facebook")
        .then((response) => {
          const redirectUrl = response.data.redirect_url;
          if (redirectUrl) {
            window.location.href = redirectUrl; // Redirect the user to the specified URL
          } else {
            // Handle the case where the redirect URL is not available
          }
        })
        .catch((error) => {
          // Handle the Facebook login error
          console.error(error.response.data);
        });
    },
    toggleInfoBox() {
      this.showInfoBox = !this.showInfoBox;
    },

    toggleInfoMobileBox() {
      this.showInfoMobileBox = !this.showInfoMobileBox;
    },

    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },

    clearSearch() {
      this.keyword = "";
      this.searches = [];
    },

    fetchData() {
      axiosInstance
        .get(`api/user-get?user_id=${this.user?.id}`)
        .then(() => { })
        .catch((error) => {
          console.log(error);
        });
    },

    search() {
      if (this.keyword === "") {
        this.searches = [];
      } else {
        axiosInstance
          .get(`api/product-detail/search`, { params: { keyword: this.keyword } })
          .then((response) => {
            console.log(response.data); // Check the entire response
            this.searches = response.data;
          })
          .catch((error) => {
            console.error(error);
            this.searches = [];
          });
      }
    },

    fetchAffiliateUserDiscount() {
      axiosInstance
        .get(`api/user-discount-create?user_id=${this.user?.id}`, {
          user_id: this.user?.id,
        })
        .then((response) => {
          this.users = response.data.user;
          this.affiliatediscounts = response.data.affiliatediscount;
        });
    },

    cartLoad() {
      var page = `api/addToCart?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.carts = data.cart;
        this.isLoading = false; // Set loading state to false after data is received
      });
    },

    shuffleArray(array) {
      let currentIndex = array.length,
        randomIndex,
        temporaryValue;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },

    showRandomTip() {
      this.activeTipIndex = -1; // Hide the current tip
      if (this.shuffledTips.length === 0) {
        // Shuffle the tips array if it's empty or all tips have been shown
        this.shuffledTips = this.shuffleArray([...this.tips]);
      }
      const nextTip = this.shuffledTips.pop();
      this.activeTipIndex = this.tips.findIndex((tip) => tip.id === nextTip.id);

      // Schedule the next tip to be shown after a delay (e.g., 5 seconds)
      this.tipTimer = setTimeout(this.showRandomTip, 10000);
    },
    hideTip() {
      this.activeTipIndex = -1;
      clearTimeout(this.tipTimer);
    },

    categoryLoad() {
      var page = `api/user-view?user_id=${this.user?.id}`;
      this.loading = true;
      axiosInstance.get(page).then(({ data }) => {
        this.categories = data.category;
        this.childcategories = data.childcategory;
        this.logos = data.logo;
        this.tips = data.tip;
        this.subs = data.subs;
        this.prodc = data.prodc;
        this.showRandomTip();
        this.loading = false;
      });
    },

    logout() {
      // localStorage.clear();
      this.publicKey = null; // Clear the publicKey property
      Cookies.remove("token");
      router.replace("/");
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },

  },

  beforeUnmount() {

    window.removeEventListener("resize", this.checkIfPC);
  },

  computed: {

    shouldDisplayResults() {
      return this.searches.length > 0 && this.keyword.trim() !== '';
    },

    userId() {
      return this.$route.params.id;
    },
    // role() {
    //   return localStorage.getItem("role");
    // },
    role() {
      return this.user?.roles;
    },
    isSearchEmpty() {
      return this.keyword === "";
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1366px) {
  .category-nav {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .category-nav {
    display: none !important;
  }

  .favNav {
    padding-left: 8px;
  }

  .top-nav {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 30px;
    max-height: 125px !important;
  }

  .search-form {
    flex: 1;
    /* Allow the search form to take up remaining space */
    margin-right: 35px;
    max-width: 200px;
    padding-right: 10px !important;
  }

  .logo-icon {
    max-width: 20%;
    /* Adjust as needed */
    flex: 0 0 auto;
    /* Don't allow the logo to take up remaining space */
    display: none !important;
  }

  .user,
  .favNav,
  .shopping-bag {
    margin-left: auto;
    /* Push these items to the right */

  }

  .navbar-text {
    margin-right: 10px;
    /* Add spacing between items */
  }

  .search-results {
    max-width: 100%;
    top: 0 !important;
    margin-top: 0 !important;
    text-align: start !important;
    align-items: start !important;
    align-content: start !important;
    padding-bottom: 50px !important;
  }
}

.button-cart:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.search-form {
  text-align: center;
  margin-top: 20px;
}

.search-results {
  border: 1px solid #eee;
  text-align: center;
  align-items: center;
  position: absolute;
  max-height: 600px;
  overflow-y: auto;
  width: 650px !important;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
  background-color: white;
  top: 50px;
  padding-top: 30px !important;
}

.card {
  border-radius: 10px;
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.no-results {
  text-align: center;
  margin-top: 20px;
  color: #777;
}

.see-more:hover {
  color: #75B0B3;
  cursor: pointer;
}

.vl {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #ddd;
  display: inline-block;
  /* Make the vertical line inline */
}

.sub_name:hover {
  transition: 1s ease-in-out;
  color: #75B0B3 !important;
  cursor: pointer;
}

.cat-name {
  position: relative;
  display: inline-block;
}

.category-hover-box {
  position: fixed;
  top: 150px;
  left: 48%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  width: 1100px;
  overflow-y: auto;
  z-index: 1000;
}

.additional-content {
  /* Adjust styles for additional content */
  display: inline-block;
  /* Make the additional content inline */
  margin-left: 10px;
  /* Adjust as needed */
}

.cat-name:hover .category-hover-box {
  opacity: 1;
  max-height: 500px;
  /* Adjust the value to fit your content */
}

.additional-text {
  margin-left: 10px;
  /* Adjust the margin as needed */
}


.trash:hover {
  cursor: pointer
}

.btn-close {
  background-color: #6c757d;
  border-radius: 50% !important;
  cursor: pointer;
}

.user-nav {
  overflow-y: auto;
  z-index: 1000;
  position: absolute;
  top: 45px;
  margin-right: 10% !important;
  /* Initially hidden */
  width: 180px;
  height: 150px;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  opacity: 0;
  /* Initially hidden */
  pointer-events: none;
  /* Disable interactions when hidden */
}

.user-nav.show {
  opacity: 1;
  /* Visible */
  pointer-events: auto;
  /* Enable interactions when visible */
}

.right-nav {
  overflow-y: auto;
  z-index: 1000;
  position: fixed;
  top: 27px;
  right: -380px;
  /* Initially hidden */
  width: 380px;
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  padding: 20px;
}

.right-nav.show {
  right: 0;
}

.favNav:hover {
  cursor: pointer;
}

.user:hover {
  cursor: pointer;
}

/* Pi CSS styles */

.cat-name i {
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.cat-name:hover i {
  transform: translateY(-10%) rotate(-180deg);
  /* margin-top: 10px !important; */
}

.search-form {
  width: 450px;
  /* Set the width as needed */
  margin: 0 auto;
  /* Center the form */
  position: relative;
}

.form-control {
  border: none;
  border-radius: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding-right: 40px;
  /* Adjust as needed to accommodate the button */
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: none;
  /* Change the background color as needed */
  color: #fff;
  border-radius: 0;
}

.badge {
  position: absolute;
  top: 0;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 4px;
  font-size: 12px !important;
}

.shopping-bag {
  background-color: #fff;
  opacity: 0.6;
  border-radius: 50px;
  padding: 10px !important;
  color: black;
  font-size: 10px !important;
}

.shopping-bag:hover {
  cursor: pointer;
}

.navbar {
  align-items: center;
}

.logo-icon {
  display: flex;
  align-items: center;
}

.search-form {
  margin-left: auto;
  margin-right: auto;
}

.navbar-text {
  position: relative;
  display: flex;
  align-items: center;
}

.shopping-bag {
  margin-left: auto;
}

/* .navbar-text {
} */

.pi {
  font-size: 1.5em !important;
}


.icon {
  /* Add your icon styles here */
  cursor: pointer;
}

.dropdown-menu {
  /* Existing styles */
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  display: none;
  top: 10;
  left: auto;
  /* Set left to auto */
  right: 0;
  /* Set right to the desired distance from the right edge */
}

.dropdown-menu li {
  padding: 8px 16px;
}

.dropdown-menu li:hover {
  background-color: #ddd;
  cursor: pointer;
}

/* Show the dropdown when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

a:hover {
  background-color: transparent;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hamburger-button {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 999;
}

.prof:hover {
  cursor: pointer;
}

.hamburger-button span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #000;
  margin-bottom: 6px;
}

/* Style the content container */
.content-container {
  padding-top: 10%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.buttons:hover {
  background-color: #666666 !important;
}

/* Style the close button */
.close-button {
  color: black;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.account:hover {
  cursor: pointer;
}

.users:hover {
  cursor: pointer;
}

.close-icon {
  font-size: 24px;
  color: black;
}

@media screen and (min-width: 992px) {
  .mobile {
    display: none;
    width: 100%;
  }

  .top-cat {
    display: none;
  }

  .mobile-logo {
    display: none;
  }

  .hamburger-button {
    display: none;
  }

  .search-input-container {
    margin-top: 15px;
  }
}

@media screen and (min-width: 767px) {


  /* Put the styles for smaller screens, like iPhones, here */
  .mobile {
    display: none;
    width: 100%;
  }

  .top-cat {
    display: none;
  }

  .mobile-logo {
    display: none;
  }

  .hamburger-button {
    display: none;
  }

  .search-input-container {
    margin-top: 15px;
  }
}

@media screen and (max-width: 993px) {
  .fa-sign-in-alt:hover {
    cursor: pointer;
  }

  .fa-sign-out-alt:hover {
    cursor: pointer;
  }

  .info-mobile-box {
    /* display: none; */
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    width: auto;
    height: auto;
    z-index: 999999;
  }

  .info-mobile-box.active {
    display: block;
    background-color: white;
    max-width: 100px;
    width: 150px;
    z-index: 99999;
  }

  body {
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
  }

  .icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: white;
    z-index: 9999;
  }

  .mobile-i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    /* Set the desired width */
    height: 50px;
    /* Set the desired height */
    font-size: 25px;
    border-radius: 50%;
    background-color: #2e73bb;
    color: white;
    flex-shrink: 0;
    margin: 5px;
  }

  nav {
    z-index: 999;
  }

  .categories-container {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  .categories {
    display: flex;
    padding-right: 32px !important;
    gap: 10px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .categories::-webkit-scrollbar {
    display: none;
  }

  .top-cat {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
    flex-shrink: 0;
  }

  .hamburger-button {
    position: absolute;
    top: 60px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 99999;
  }

  .hamburger-button span {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #000;
    margin-bottom: 6px;
  }

  .content-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    z-index: 9999;
    /* Ensure the container appears on top of other elements */
    overflow-y: auto;
    /* Enable vertical scrolling if content exceeds container height */
  }

  .close-button {
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .close-icon {
    font-size: 35px;
    font-weight: 600;
  }

  .content {
    margin-top: 5px;
  }

  .content li {
    margin-bottom: 10px;
  }

  .router-link-active {
    font-weight: bold;
  }

  .search-input {
    width: 80% !important;
    margin-top: 1%;
    margin-left: 2%;
    margin-bottom: 5%;
  }

  .header-container .top-cat {
    display: block;
  }

  .mobile-logo {
    margin-bottom: 3%;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-input-container {
    display: flex;
    align-items: center;
  }

  .open-cart {
    display: flex;
    align-items: center;
  }

  .mobile-cart {
    position: absolute !important;
    right: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
  }

  .container {
    display: flex;
  }

  .open-cart {
    margin-left: 5px;
  }

  .hamburger-button {
    margin-left: 5px;
  }

  .content-container {
    display: flex;
  }

  .info-box {
    display: none;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    width: 200px;
    height: 150px;
  }

  .info-box.active {
    display: block;
    background-color: #e3f7f4;
    max-width: 100px;
    width: 150px;
    z-index: 99999;
  }
}

/* Mobile Buttons Container */
.mobile-buttons-container {
  display: none;
  /* Hide the container by default */
}

/* Show the container only on screens with a maximum width of 767px (adjust as needed) */
@media (max-width: 767px) {
  .mobile-buttons-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* Set your desired background color */
    border-radius: 30px;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: Add a box shadow */
  }

  /* Optional: Adjust icon styles */
  .mobile-i {
    font-size: 24px;
    margin: 0 10px;
    color: white;
    /* Set your desired icon color */
  }
}

.popup-container {
  position: relative;
}

#lamp {
  cursor: pointer;
}

@media (max-width: 767px) {
  .wtsp {
    position: fixed;
    bottom: 85px;
    right: 15px;
    z-index: 99999;
  }

  #lamp {
    position: fixed;
    bottom: 160px;
    right: 1px;
    z-index: 99999;
  }

  #lamp-hover {
    position: fixed;
    bottom: 160px;
    right: 1px;
    z-index: 99999;
  }

  .animated-data {
    position: fixed;
    bottom: 150px !important;
    right: -35% !important;
    width: 300px;
    /* Adjust the width as needed */
    height: auto;
    /* Adjust the height as needed */
    border-radius: 10px;
    /* Rounded corners for the balloon */
    background-color: #f0f0f0;
    /* Background color of the balloon */
    border: 1px solid #ccc;
    /* Border color */
    opacity: 0;
    transform-origin: center;
    transform: scale(0.5);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 999;
  }

}

.lamp-container {
  position: fixed;
  bottom: 100px;
  right: 7px;
  z-index: 99999;
}

#lamp-hover {
  display: none;
}

.lamp-container:hover #lamp {
  display: none;
}

.lamp-container:hover #lamp-hover {
  display: block;
}

.animated-data {
  position: fixed;
  bottom: 4%;
  right: -50px;
  width: 350px;
  /* Adjust the width as needed */
  height: auto;
  /* Adjust the height as needed */
  border-radius: 10px;
  /* Rounded corners for the balloon */
  background-color: #f0f0f0;
  /* Background color of the balloon */
  border: 1px solid #ccc;
  /* Border color */
  opacity: 0;
  transform-origin: center;
  transform: scale(0.5);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 999;
}

.speech-bubble {
  padding: 10px;
  position: relative;
  border-radius: 10px;
}

.speech-bubble::before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 50%;
  border-width: 10px;
  border-style: solid;
  border-color: #f0f0f0 transparent transparent transparent;
  transform: translateX(50%);
}

.animated-data.show {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  /* Scale up and show the balloon */
}

.animated-data:hover {
  cursor: pointer;
  /* Change cursor to indicate interactivity */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  /* Add a shadow for depth */
}

.tip-fade-enter-active,
.tip-fade-leave-active {
  transition: opacity 0.3s;
}

.tip-fade-enter,
.tip-fade-leave-to

/* .tip-fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

.container {
  position: relative;
}

/* Apply styles for mobile screens */
@media (max-width: 767px) {
  .mobile-search-results {
    position: absolute;
    top: 50px;
    /* Position below the input */
    left: -40px;
    width: 100%;
    background-color: #ffffff;
    /* Adjust the background color as needed */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a box shadow for a nice effect */
    max-height: 450px;
    /* Set a maximum height for scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling if content exceeds the maximum height */
  }

  .mobile-search-results li {
    list-style: none;
  }

  .mobile-search-results li hr {
    margin: 5px 0;
  }

  .mobile-search-results li img {
    margin-right: 10px;
  }
}
</style>
