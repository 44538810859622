<template>
  <div class="main-panel ms-5">
    <div class="content-wrapper container">

      <!-- Brand selection dropdown -->
      <div class="mt-5">
        <label for="brand">Select Brand:</label>
        <select v-model="selectedBrand" @change="orderLoad" class="form-control mb-4">
          <option value="">Select Brand</option>
          <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.brand_name }}</option>
        </select>
      </div>

      <!-- Only display content if a brand is selected -->
      <div v-if="selectedBrand">
        <div class="row">
          <div class="col-6">
            <label for="fromDate">From Date:</label>
            <input type="date" class="form-control" v-model="fromDate" @change="orderLoad">
          </div>
          <div class="col-6">
            <label for="toDate">To Date:</label>
            <input type="date" class="form-control" v-model="toDate" @change="orderLoad">
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <label for="search">Search by Title or SKU:</label>
            <input type="text" class="form-control" v-model="searchQuery" @input="orderLoad">
          </div>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col-6">
            <label for="sortDirection">Sort Order:</label>
            <select class="form-control" v-model="selectedSortOrder" @change="sortTable">
              <option value="asc">From Min To Max</option>
              <option value="desc">From Max To Min</option>
            </select>
          </div>
        </div>

        <div v-if="showZoomedImage" id="zoomedImageContainer" style="width: 100px; height: 100px;">
          <div class="zoomedImageContent" style="width: 100%; height: 100%;">
            <span class="close" @click="closeZoomedImage">&times;</span>
            <img :src="zoomedImageSrc" alt="Zoomed Image" style="max-width: 100%; max-height: 100%;">
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h3 class="mb-5">Orders Panel</h3>
            <table class="responsive table-responsive table w-100">
              <thead>
              <tr>
                <th>#</th>
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Product Sku</th>
                <th>Main Price</th>
                <th>Sale Price</th>
                <th>Sold Quantity</th>
                <th>Current quantity</th>
                <th>Expiry Date</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(order, index) in allOrder" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <th> <img @click="zoomImage(order.product_image)"
                          :src="'https://api.gocami.com/product_detail/' + order.product_image" width="50" height="50" />
                </th>
                <td><router-link class="link-to"
                                 :to="{ name: 'AffiliateOrderView', params: { id: order.product_id } }">{{
                    order.product_name }}</router-link></td>
                <td>{{ order.product_sku }}</td>
                <td v-if="order.main_price" class="text-center fw-bolder">${{ order.main_price.toFixed(2) }}</td>
                <td v-else class="text-center">-</td>
                <td v-if="order.sale_price">${{ order.sale_price.toFixed(2) }}</td>
                <td v-else>-</td>
                <td class="text-primary fw-bold">{{ order.ordered_times }}</td>
                <td v-if="order.current_quantity == 0" class="text-white bg-danger fw-bold">{{
                    order.current_quantity }}</td>
                <td v-else-if="order.current_quantity < 10" class="text-white bg-warning fw-bold">{{
                    order.current_quantity }}</td>
                <td v-else class="text-danger fw-bold">{{ order.current_quantity }}</td>
                <td :class="getExpiryClass(order.expiry_date)" v-if="order.expiry_date">{{ order.expiry_date }}</td>
                <td v-else>No Expiry Date</td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> <!-- End conditional content -->
    </div>
  </div>

</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: "CartInvoice",

  data() {
    return {
      allOrder: [],
      brands: [],
      suppliers: [],
      selectedBrand: '',
      selectedSupplier: '',
      fromDate: '',
      toDate: '',
      sortColumn: null,
      sortDirection: 'asc', // default sorting direction
      availableSortColumns: ['current_quantity', 'ordered_times', 'expiry_date'],
      selectedSortColumn: 'current_quantity', // default column to sort by
      selectedSortOrder: 'asc', // default sorting order
      updatedMainPrice: null,
      showZoomedImage: false,
      zoomedImageSrc: ''
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {
    zoomImage(imageSrc) {
      console.log('Zoom image clicked');
      console.log('Image source:', imageSrc);
      this.zoomedImageSrc = 'https://api.gocami.com/product_detail/' + imageSrc;
      console.log('Zoomed image source:', this.zoomedImageSrc);
      this.showZoomedImage = true;
    },

    closeZoomedImage() {
      console.log('Close zoomed image clicked');
      this.showZoomedImage = false;
    },

    getExpiryClass(expiryDate) {
      const expiryDateObj = new Date(expiryDate);
      const currentDate = new Date();
      const monthsDifference = (expiryDateObj.getFullYear() - currentDate.getFullYear()) * 12 + (expiryDateObj.getMonth() - currentDate.getMonth());

      if (monthsDifference <= 0) {
        return 'bg-danger';
      } else if (monthsDifference === 1) {
        return 'bg-warning';
      } else {
        return 'bg-success';
      }
    },

    sortTable() {
      // Sort the products array based on the selected column and direction
      this.allOrder.sort((a, b) => {
        const modifier = this.selectedSortOrder === 'desc' ? -1 : 1;

        // Check if the selected column is 'expiry_date'
        if (this.selectedSortColumn === 'expiry_date') {
          const dateA = new Date(a.expiry_date);
          const dateB = new Date(b.expiry_date);
          if (dateA < dateB) return -1 * modifier;
          if (dateA > dateB) return 1 * modifier;
          return 0;
        } else {
          // For other columns, perform regular comparison
          if (a[this.selectedSortColumn] < b[this.selectedSortColumn]) return -1 * modifier;
          if (a[this.selectedSortColumn] > b[this.selectedSortColumn]) return 1 * modifier;
          return 0;
        }
      });
    },

    orderLoad() {
      // console.log('Order load method called');
      // console.log('Search query:', this.searchQuery);
      var endpoint = `api/super-admin-selled-product`;
      var params = {
        brand_id: this.selectedBrand,
        supplier_id: this.selectedSupplier,
        from_date: this.fromDate,
        to_date: this.toDate,
        sort_column: this.selectedSortColumn,
        sort_order: this.selectedSortOrder,
        search: this.searchQuery // Combine search query for title and sku
      };

      axiosInstance.get(endpoint, { params }).then(({ data }) => {
        console.log(data);
        this.brands = data.brands;
        this.allOrder = data.products;
        this.suppliers = data.suppliers;
      });
    },

  },
};
</script>

<style scoped>
.link-to:hover {
  color: red;
}

.bg-danger {
  background-color: red;
}

.bg-warning {
  background-color: orange;
}

.bg-success {
  background-color: green;
}

/* New styles for zoomed image container */
#zoomedImageContainer {
  display: block;
  /* Add this line to make the container visible */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.9); */
  overflow: auto;
}

.zoomedImageContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 999999 !important;
}

.zoomedImageContent img {
  z-index: 99999 !important;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #fff;
  /* Optional: Add a border around the image */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  /* Optional: Add a box shadow */
}

.close {
  color: red !important;
  position: absolute;
  top: 10px;
  right: 110px;
  font-size: 30px !important;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.close:hover {
  transform: scale(1.2);
  /* Optional: Increase the size of the close icon on hover */
}
</style>