<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <!-- Filter by brand -->
          <select v-model="selectedBrand" @change="loadProductsByBrand" class="my-3 form-select">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <!-- New filter for price comparison -->
          <select v-model="priceComparisonFilter" @change="filterProductsByPriceComparison" class="my-3 form-select">
            <option value="all">All</option>
            <option value="equal">Sale Price == New Price</option>
            <option value="different">Sale Price != New Price</option>
            <option value="null">New Price is NULL</option>
          </select>

          <br/>
          <button @click="updateCheckedItems" class="btn btn-primary mb-3 me-5">
            Update Checked Items
          </button>
          <table class="table w-100">
            <thead class="fixed-header border-bottom">
            <tr>
              <th>#</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Sale Price</th>
              <th>Image</th>
              <th>Main Cost</th>
              <th>Sale Price (NEW)</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
              <th>Select</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in filteredProducts" :key="product.id">
              <td>{{ product.id }}</td>
              <td>
                <router-link :to="{ name: 'ProductDetailLog', params: { id: product.id } }">
                  {{ product.sku }}
                </router-link>
              </td>
              <td>{{ product.title }}</td>
              <td v-if="product.sale_price">${{ product.sale_price }}</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75"/></td>
              <td><input type="text" class="form-control" v-model="product.main_price"/></td>
              <td><input type="text" class="form-control" v-model="product.new_price"/></td>
              <td><input type="text" class="form-control" v-model="product.total_commission"/></td>
              <td><input type="text" class="form-control" v-model="product.affiliate_commission"/></td>
              <td><input type="text" class="form-control" v-model="product.sub_affiliate_commission"/></td>
              <td><input type="checkbox" class="form-check-input" v-model="product.checked"/></td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import ProductDetailLog from "@/components/SuperAdmin/ProductDetailLog.vue";

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      priceComparisonFilter: 'all', // New state variable for filtering
      brands: [],
      products: [],
      childcategory: [],
      file: null,
    };
  },

  created() {
    this.fetchAllData();
  },

  computed: {
    ProductDetailLog() {
      return ProductDetailLog
    },
    // Apply the price comparison filter
    filteredProducts() {
      if (this.priceComparisonFilter === 'equal') {
        return this.productDetail.filter(
            (product) => product.sale_price == product.new_price
        );
      } else if (this.priceComparisonFilter === 'different') {
        return this.productDetail.filter(
            (product) => product.sale_price != product.new_price
        );
      } else if (this.priceComparisonFilter === 'null') {
        return this.productDetail.filter(
            (product) => product.new_price == null
        );
      } else {
        return this.productDetail; // If 'all', return all products
      }
    },
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.fetchAllData(this.selectedBrand);
      }
    },

    fetchAllData(brandId) {
      return axiosInstance
          .get(`api/target-change/${brandId}`)
          .then((response) => {
            this.productDetail = response.data.productDetail;
            this.brands = response.data.brands;
          })
          .catch((error) => {
            console.error('Error fetching main data:', error);
          });
    },

    updateCheckedItems() {
      const checkedProducts = this.productDetail.filter((product) => product.checked);
      if (checkedProducts.length === 0) {
        alert("Please select at least one item to update.");
        return;
      }

      checkedProducts.forEach((product) => {
        this.updateDescription(product);
      });
    },

    updateDescription(product) {
      axiosInstance
          .post(`api/update-target-super-admin/${product.id}`, {
            main_price: product.main_price,
            new_price: product.new_price,
            total_commission: product.total_commission,
            affiliate_commission: product.affiliate_commission,
            sub_affiliate_commission: product.sub_affiliate_commission,
          })
          .then(() => {
            this.fetchAllData(this.selectedBrand);
          })
          .catch((error) => {
            console.error('Error updating details:', error);
          });
    },

    // Method to apply price comparison filter
    filterProductsByPriceComparison() {
      // The filter logic is in the computed property `filteredProducts`
    },
  },
};
</script>


<style scoped>
.fixed-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  background-color: #ddd !important;
  border-bottom: 1px solid black !important;
  /* Adjust as needed */
}
</style>