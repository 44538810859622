<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <p>Current Date and Time: {{ currentDate }}</p>
          <div v-for="(brandData, brandName) in brandResults" :key="brandName">
            <h2>Brand: {{ brandName }}</h2>

            <table class="table table-responsive border">
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>SKU</th>
                <th>Barcode</th>
                <th>Barcode Input</th>
                <th>Quantity</th>
                <th>Location</th>
              </tr>
              <tr v-for="(cart, index) in brandData" :key="index" class="d-flex">
                <td><img :src="'https://api.gocami.com/product_detail/' + cart.image " width="120"/></td>
                <td>{{ cart.title }}</td>
                <td>{{ cart.sku }}</td>
                <td>{{ cart.barcode }}</td>
                <td><input type="text" class="form-control" v-model="cart.inputBarcode" @blur="updateBarcode(cart)" /></td>
                <td>( {{ cart.total_quantity }} )</td>
                <td>{{ cart.zone_name }} - {{ cart.floor_name }} - {{ cart.stand_name }} - {{ cart.shelve_name }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: "OldSuperAdminOrder",

  data() {
    return {
      brandResults: [],
      currentDate: new Date().toLocaleString()
    };
  },

  created() {
    this.getCarts();
    setInterval(this.updateCurrentDateTime, 1000); // Update every second (1000 milliseconds)
  },

  methods: {
    getCarts() {
      var page = `api/cart-details`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.brandResults = data;
      });
    },

    updateCurrentDateTime() {
      const now = new Date();
      this.currentDate = now.toLocaleString();
    },

    updateBarcode(cart) {
      if (cart.inputBarcode === cart.barcode) {
        axiosInstance.post('api/update-barcode', {
          barcode: cart.barcode,
          product_detail_id: cart.product_detail_id
        })
            .then(response => {
              if (response.data.success) {
                console.log('Barcode updated successfully');
              }
            })
            .catch(error => {
              console.error('Error updating barcode:', error);
            });
      }
    }
  },
};
</script>
