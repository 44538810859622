<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <!-- Brand Select -->
          <select v-model="selectedBrand" @change="loadProductsByBrand" class="form-select my-3">
            <option value="" disabled>Select a brand</option>
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <!-- Product Select -->
          <select v-if="products.length" v-model="selectedProduct" class="form-select my-3">
            <option value="" disabled>Select a product</option>
            <option v-for="product in products" :key="product.id" :value="product.id">
              {{ product.product_name }}
            </option>
          </select>

          <!-- Name Input -->
          <input type="text" v-model="jardeName" placeholder="Enter name" class="form-control my-3" />

          <!-- Submit Button -->
          <button @click="submitJarde" class="btn btn-primary">Submit</button>

        </div>
      </div>
    </div>
  </div>
</template>



<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      selectedProduct: null,
      jardeName: '', // New data property to store the name
      brands: [],
      products: [],
    };
  },

  created() {
    this.fetchAllBrands();
  },

  methods: {
    fetchAllBrands() {
      return axiosInstance.get('api/get-jarde-products/0')
          .then(response => {
            this.brands = response.data.brands;
            this.products = []; // Reset products when loading brands
          })
          .catch(error => {
            console.error('Error fetching brands:', error);
          });
    },

    loadProductsByBrand() {
      if (this.selectedBrand) {
        axiosInstance.get(`api/get-jarde-products/${this.selectedBrand}`)
            .then(response => {
              this.products = response.data.products;
            })
            .catch(error => {
              console.error('Error fetching products:', error);
            });
      } else {
        this.products = []; // Clear products if no brand is selected
      }
    },

    submitJarde() {
      if (this.selectedProduct && this.jardeName) {
        axiosInstance.post('api/insert-jarde', {
          product_id: this.selectedProduct,
          name: this.jardeName
        })
            .then(() => {
              alert('Jarde inserted successfully!');
              this.selectedBrand = null;
              this.selectedProduct = null;
              this.jardeName = '';
              this.products = [];
            })
            .catch(error => {
              console.error('Error inserting jarde:', error);
            });
      } else {
        alert('Please select a product and enter a name.');
      }
    }
  },
};

</script>


<style scoped>
.fixed-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
  background-color: #ddd !important;
  border-bottom: 1px solid black !important;
  /* Adjust as needed */
}
</style>