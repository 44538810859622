<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

          <!-- Success or Error Message -->
          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">
            {{ message }}
          </div>

          <!-- Input Field for Barcode -->
          <div class="mb-3">
            <label for="barcodeInput">Scan Invoice Barcode:</label>
            <input
                type="text"
                id="barcodeInput"
                v-model="scannedBarcode"
                @input="handleBarcodeInput"
                class="form-control"
                placeholder="Enter invoice barcode"
            />
          </div>

          <!-- Update Button -->
          <button @click="updateInvoices" class="btn btn-primary">Update Invoices</button>

          <!-- Tabs Content -->
          <div class="tab-content mt-4" id="orderTabsContent">
            <!-- All Orders Tab -->
            <div v-if="currentTab === 'allOrders'" class="tab-pane active">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Created at</th>
                  <th>Barcode</th>
                  <th>Number of Boxes</th>
                  <th>Scanned Count</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="order in allOrder" :key="order.id">
                  <th scope="row">{{ order.id }}</th>
                  <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                  <td>{{ order.created_at }}</td>
                  <td>{{ order.invoice_barcode }}</td>
                  <td>{{ order.number_of_boxes }}</td>
                  <td><input type="text" :value="order.taxed_number_of_boxes" readonly class="form-control" /></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: 'OrderTracking',

  data() {
    return {
      currentTab: 'allOrders', // Track the current tab
      allOrder: [],
      message: '', // To display success or error messages
      isSuccess: true, // To determine the type of message (success or error)
      scannedBarcode: '', // For tracking the barcode input
    };
  },

  created() {
    this.orderLoad(); // Load orders from the server
  },

  methods: {
    orderLoad() {
      axiosInstance.get('api/aramex-boxes')
          .then(({ data }) => {
            this.allOrder = data;
            this.loadStoredInvoices(); // Merge with stored invoices
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

    loadStoredInvoices() {
      const storedInvoices = JSON.parse(localStorage.getItem('storedInvoices') || '[]');

      storedInvoices.forEach(storedInvoice => {
        const order = this.allOrder.find(order => order.id === storedInvoice.id);
        if (order) {
          order.taxed_number_of_boxes = storedInvoice.taxed_number_of_boxes;
        }
      });
    },

    handleBarcodeInput() {
      const barcode = this.scannedBarcode.trim();

      if (barcode !== '') {
        const matchingOrders = this.allOrder.filter(order => order.invoice_barcode === barcode);

        if (matchingOrders.length > 0) {
          let orderUpdated = false;

          for (const order of matchingOrders) {
            if (!order.taxed_number_of_boxes) {
              order.taxed_number_of_boxes = 0;
            }

            if (order.taxed_number_of_boxes < order.number_of_boxes) {
              order.taxed_number_of_boxes += 1;
              orderUpdated = true;

              // Store the updated order in localStorage
              this.storeInvoiceLocally(order);

              this.scannedBarcode = ''; // Clear input after a successful match
              this.message = `Box scanned successfully for invoice: ${barcode}`;
              this.isSuccess = true;
              break;
            }
          }

          if (!orderUpdated) {
            this.message = `Maximum number of boxes already scanned for all invoices with barcode: ${barcode}`;
            this.isSuccess = false;
          }
        } else {
          this.message = `Invoice barcode: ${barcode} not found.`;
          this.isSuccess = false;
        }
      }
    },

    storeInvoiceLocally(order) {
      let storedInvoices = JSON.parse(localStorage.getItem('storedInvoices') || '[]');
      const existingInvoice = storedInvoices.find(storedInvoice => storedInvoice.id === order.id);

      if (existingInvoice) {
        existingInvoice.taxed_number_of_boxes = order.taxed_number_of_boxes;
      } else {
        storedInvoices.push({
          id: order.id,
          taxed_number_of_boxes: order.taxed_number_of_boxes,
        });
      }

      localStorage.setItem('storedInvoices', JSON.stringify(storedInvoices));
    },

    updateInvoices() {
      // Collect the relevant invoice data, including taxed_number_of_boxes
      const invoicesData = this.allOrder.map(order => ({
        id: order.id,
        taxed_number_of_boxes: order.taxed_number_of_boxes
      }));

      axiosInstance.post('api/aramex-invoices', { invoices: invoicesData })
          .then(response => {
            this.message = response.data.message;
            this.isSuccess = response.data.success;

            if (this.isSuccess) {
              // Clear the stored invoices from localStorage on success
              localStorage.removeItem('storedInvoices');
            }
          })
          .catch(error => {
            this.message = 'Error updating invoices: ' + error.message;
            this.isSuccess = false;
          });
    },
  },
};
</script>




<style scoped>
.nav-link.active {
  background-color: #007bff;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
