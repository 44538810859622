<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card shadow-sm border-0">
        <div class="card-body">
          <div v-if="logs.length > 0" class="logs-container">
            <h3 class="mb-4">Product Logs</h3>
            <table class="table table-hover table-bordered">
              <thead class="table-dark">
              <tr>
                <th>Date</th>
                <th>Changed Fields</th>
                <th>Old Values</th>
                <th>New Values</th>
                <th>User ID</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="log in logs" :key="log.id">
                <td>{{ new Date(log.created_at).toLocaleString() }}</td>
                <td>
                  <ul>
                    <li v-for="(field, index) in parseFields(log.changed_fields)" :key="index">{{ field }}</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li v-for="(value, index) in parseFields(log.old_values)" :key="index">{{ value }}</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li v-for="(value, index) in parseFields(log.new_values)" :key="index">{{ value }}</li>
                  </ul>
                </td>
                <td>{{ log.user_id || 'N/A' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p>No logs available for this product.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import {useRoute} from 'vue-router';
import axiosInstance from '../../../axiosInstance';

export default {
  setup() {
    const logs = ref([]);
    const route = useRoute();

    const fetchLogs = async () => {
      try {
        const response = await axiosInstance.get(`/api/products/${route.params.id}`);
        logs.value = response.data.logs;
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    const parseFields = (fieldString) => {
      return fieldString ? fieldString.split(',') : [];
    };

    onMounted(() => {
      fetchLogs();
    });

    return {
      logs,
      parseFields
    };
  }
};
</script>

<style scoped>
.logs-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

thead {
  background-color: #343a40;
  color: white;
}

tbody tr:hover {
  background-color: #f2f2f2;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  margin-bottom: 5px;
}

td {
  vertical-align: top;
}

th, td {
  padding: 12px 15px;
}
</style>
