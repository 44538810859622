<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
            {{ message }}
          </div>

          <h3 class="mb-5">Affiliate Order Panel</h3>

          <div class="row mb-5">
            <div class="col-6">
              <label for="brand-select">Select Brand:</label>
              <select class="form-control w-100" id="brand-select" v-model="selectedBrandId"
                      @change="filterProductDetails">
                <option value="" hidden selected>All Brands</option>
                <option value="">All Brands</option>
                <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.brand_name }}
                </option>
              </select>
            </div>


          </div>

          <table class="table table-responsive">
            <thead>
            <tr>
              <th>Image</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Old Price</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Quantity</th>
              <th>Items Left</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="productDetail in filteredProductDetails" :key="productDetail.id">
              <td>
                <div class="image-container">
                  <img :src="'https://api.gocami.com/product_detail/' + productDetail.image"
                       class="product-image"
                       @mouseenter="enlargeImage('https://api.gocami.com/product_detail/' + productDetail.image)"/>
                  <div class="enlarged-image">
                    <img :src="'https://api.gocami.com/product_detail/' + productDetail.image"/>
                  </div>
                </div>
              </td>
              <td>{{ productDetail.sku }}</td>
              <td>{{ productDetail.title }}</td>
              <td v-if="productDetail.old_price">
                <del class="text-danger">$ {{ productDetail.old_price.toFixed(2) }}</del>
              </td>
              <td v-else>-</td>
              <td>$ {{ (productDetail.sale_price - (productDetail.sale_price * productDetail.affiliate_bazar_discount / 100)).toFixed(2) }}</td>
              <td>%{{ productDetail.affiliate_bazar_discount }}</td>
              <td v-if="getProductConfigurationQuantity(productDetail.id) > 0 && productDetail.max_quant > 0">
                <div class="input-group d-flex align-items-center">
                  <button class="btn btn-outline-secondary btn-quantity" type="button"
                          @click="decreaseQuantity(productDetail)">-
                  </button>

                  <input type="number" class="form-control custom-input"
                         v-model.number="productDetail.quantity"
                         :max="getProductConfigurationQuantity(productDetail.id)"
                         @change="updateCartQuantity(productDetail.quantity)" placeholder="Qty"
                         required/>

                  <button class="btn btn-outline-secondary btn-quantity" type="button"
                          @click="increaseQuantity(productDetail)"
                          :disabled="productDetail.quantity >= 20">+
                  </button>
                </div>

                <div class="error-message"
                     v-if="productDetail.quantity > getProductConfigurationQuantity(productDetail.id)">
                  Quantity exceeds available stock
                </div>
                <button v-else type="submit" class="btn btn-success add-to-cart-button"
                        @click="addToCart(productDetail)">
                  Add to cart
                </button>

                <span class="text-danger">Only {{ getProductSaleQuantity(productDetail.id) }} item available per day</span>
              </td>
              <td v-else>
                <div class="input-group">
                  <input type="text" class="form-control custom-input" :value="productDetail.quantity"
                         placeholder="Qty" readonly/>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary btn-quantity" type="button"
                            @click="decreaseQuantity(productDetail)">-
                    </button>
                    <button class="btn btn-outline-secondary btn-quantity" type="button"
                            @click="increaseQuantity(productDetail)" disabled>+
                    </button>
                  </div>
                </div>
                <div class="out-of-stock-message"
                     v-if="getProductConfigurationQuantity(productDetail.id) === 0">
                  Out of stock
                </div>
              </td>
              <td class="text-success">{{ productDetail.max_quant }}</td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import {useUserStore} from "../../../../../store";

export default {
  name: "RefundView",
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      users: [],
      brands: [],
      productdetails: [],
      productConfigurations: [],
      selectedBrandId: "",
      selectedProductDetails: [],
      filteredProductDetails: [],
      showSuccessMessage: false,
      message: '',
      isError: false,
      productDetail: {
        quantity: 1
      },
      cart: {
        quantity: 1,
        user_id: '',
      }
    };
  },

  created() {
    this.productSaleLoad();
    this.filterProductDetails();
  },

  computed: {
    isUserSelected() {
      return !!this.cart.user_id;
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.addToCart) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  methods: {

    getProductSaleQuantity(productDetailId) {
      const productDetail = this.productdetails.find(detail => detail.id === productDetailId);
      if (productDetail && productDetail.productsale.length) {
        // Assuming you want the quantity from the first sale entry; adjust if needed
        return productDetail.productsale[0].max_quantity;
      }
      return 0;
    },

    enlargeImage(imageUrl) {
      const enlargedContainer = document.createElement('div');
      enlargedContainer.classList.add('enlarged-image');

      const enlargedImage = document.createElement('img');
      enlargedImage.src = imageUrl;

      enlargedContainer.appendChild(enlargedImage);

      const body = document.querySelector('body');
      body.appendChild(enlargedContainer);

      setTimeout(() => {
        enlargedContainer.remove();
      }, 1); // Adjust the delay as needed
    },

    updateCartQuantity(quantity) {
      this.cart.quantity = quantity;
    },

    addToCart(productDetail) {
      const maxQuantity = this.getProductConfigurationQuantity(productDetail.id);

      if (productDetail.quantity > maxQuantity) {
        // Show an error message or handle the validation error in your desired way
        return;
      }

      this.cart.quantity = productDetail.quantity;

      const formData = new FormData();
      formData.append("quantity", productDetail.quantity);
      formData.append("sale_price", productDetail.sale_price);
      formData.append("brand_id", productDetail.brand_id);
      formData.append("cart_discount", productDetail.affiliate_bazar_discount);
      formData.append("is_sale", productDetail.aff_sale);
      formData.append("product_detail_id", productDetail.id);
      formData.append("user_id", this.user?.id);

      axiosInstance
          .post(`api/product-discount-store`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.showSuccessMessage = true;
              this.message = "Product has been added to cart successfully"; // Update success message
              this.isError = false; // Reset isError to false (no error)
            } else {
              this.message = "Failed to add to cart"; // Update error message
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
            }
            this.hideMessageAfterDelay(5000);
          })
          .catch((error) => {
            this.message = "Failed to add to cart"; // Update error message
            this.isError = true; // Set isError to true (error)
            this.showSuccessMessage = true; // Show the toast message
            console.error("Failed to add to cart", error);
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          });
    },

    productSaleLoad() {
      const page = `api/affiliate-product-discount?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(response => {
        this.users = response.data.user;
        this.brands = response.data.brand;
        this.productdetails = response.data.productdetail;
        this.productConfigurations = response.data.productConfiguration;
      });
    },

    filterProductDetails() {
      if (this.selectedBrandId) {
        this.filteredProductDetails = this.productdetails.filter(
            product => product.brand_id === this.selectedBrandId
        );
      } else {
        this.filteredProductDetails = this.productdetails;
      }
    },

    getProductConfigurationQuantity(productDetailId) {
      const productConfiguration = this.productConfigurations.find(config => config.product_detail_id === productDetailId);
      const productDetail = this.productdetails.find(detail => detail.id === productDetailId);

      if (productDetail && productConfiguration) {
        let maxQuantity = Math.min(productConfiguration.quantity, productDetail.max_quant);

        if (productDetail.max_quant < 25) {
          maxQuantity = Math.min(maxQuantity, 25);
        }

        if (maxQuantity > productConfiguration.quantity) {
          maxQuantity = productConfiguration.quantity;
        }

        return maxQuantity;
      }

      return 0;
    },

    getMaxQuantity(productDetailId) {
      const productConfiguration = this.productConfigurations.find(config => config.product_detail_id === productDetailId);
      const productDetail = this.productdetails.find(detail => detail.id === productDetailId);

      if (productDetail && productConfiguration) {
        let maxQuantity = Math.min(productConfiguration.quantity, productDetail.max_quant);

        if (productDetail.max_quant < 25) {
          maxQuantity = Math.min(maxQuantity, 25);
        }

        if (maxQuantity > productConfiguration.quantity) {
          maxQuantity = productConfiguration.quantity;
        }

        return maxQuantity;
      }

      return 0;
    },

    decreaseQuantity(productDetail) {
      if (productDetail.quantity > 1) {
        productDetail.quantity--;
      }
    },

    increaseQuantity(productDetail) {
      const maxQuantity = this.getMaxQuantity(productDetail.id);
      if (!productDetail.quantity || isNaN(productDetail.quantity)) {
        productDetail.quantity = 0;
      }
      if (productDetail.quantity < maxQuantity) {
        productDetail.quantity++;
      }
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },
  },

  mounted() {
    this.productSaleLoad();
  }
};
</script>

<style scoped>
* {
  font-size: 12pt !important;
}

img {
  width: 50px;
  height: 50px;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  background-color: #ddd;
}

/* Styling for checked checkbox */
.custom-checkbox:checked {
  background-color: #5bc0de;
}

.input-group.d-flex {
  display: flex;
  align-items: center;
}

.input-group-append {
  display: flex;
  gap: 0.5rem;
}

/* Add custom CSS styles here */
.custom-input {
  width: 100px;
  /* Adjust the width as needed */
}

.btn-quantity {
  padding: 5px 10px;
  /* Adjust the padding as needed */
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  /* Adjust the margin as needed */
}

.out-of-stock-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  /* Adjust the margin as needed */
}

.add-to-cart-button {
  padding: 8px 16px;
  /* Adjust the padding as needed */
}

/* Image enlargement effect */
.product-image {
  transition: transform 0.3s ease;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.image-container {
  position: relative;
  display: inline-block;
}

.enlarged-image {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-width: 90%;
  max-height: 90%;
}

.enlarged-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-container:hover .enlarged-image {
  display: block;
}

@media (max-width: 767px) {
  .main-panel {
    min-width: 100%;
  }
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>