<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <button @click="exportToCSV" class="btn btn-primary mb-3">Export to CSV</button>

          <div class="row">
            <div class="col-6">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>SKU</th>
                  <th>Old Quantity</th>
                  <th>New Quantity</th>
                  <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="detail in jardeDetails" :key="detail.id">
                  <th scope="row">{{ detail.id }}</th>
                  <td>{{ detail.sku }}</td>
                  <td>{{ detail.old_quantity }}</td>
                  <td>{{ detail.new_quantity }}</td>
                  <td>{{ detail.created_at }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-6">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>SKU</th>
                  <th>Title</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="product in productDetailsNotInJarde" :key="product.id">
                  <td>{{ product.sku }}</td>
                  <td>{{ product.title }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  data() {
    return {
      jardeDetails: [],
      productDetailsNotInJarde: [],
    };
  },

  created() {
    this.loadJardeData();
  },

  methods: {
    loadJardeData() {
      const page = `api/get-jarde-quantity/${this.$route.params.id}`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.jardeDetails = data.jardeDetails;
        this.productDetailsNotInJarde = data.productDetailsNotInJarde;
      });
    },

    exportToCSV() {
      const jardeCSV = this.generateCSV(this.jardeDetails, [
        'ID',
        'SKU',
        'Old Quantity',
        'New Quantity',
        'Created At'
      ], [
        'id',
        'sku',
        'old_quantity',
        'new_quantity',
        'created_at'
      ]);

      const productCSV = this.generateCSV(this.productDetailsNotInJarde, [
        'SKU',
        'Title'
      ], [
        'sku',
        'title'
      ]);

      const combinedCSV = `Jarde Details\n${jardeCSV}\n\nProduct Details Not in Jarde\n${productCSV}`;

      // Create a blob from the combined CSV string
      const blob = new Blob([combinedCSV], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Create a URL for the blob and set it as the href for the link
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'export_data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    generateCSV(data, headers, keys) {
      // Create CSV header
      let csv = headers.join(',') + '\n';

      // Create CSV rows
      data.forEach(item => {
        csv += keys.map(key => `"${item[key] || ''}"`).join(',') + '\n';
      });

      return csv;
    }
  }
};
</script>

<style scoped></style>
