<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

          <!-- Success or Error Message -->
          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">
            {{ message }}
          </div>

          <!-- Tabs Content -->
          <div class="tab-content" id="orderTabsContent">
            <!-- All Orders Tab -->
            <div v-if="currentTab === 'allOrders'" class="tab-pane active">
              <table class="table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Created at</th>
                  <th>Address</th>
                  <th>Location</th>
                  <th>Barcode</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="order in allOrder" :key="order.id">
                  <th scope="row">{{ order.id }}</th>
                  <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                  <td>{{ order.created_at }}</td>
                  <td>{{ order.address }}</td>
                  <td>{{ order.governorates_name }} - {{ order.city_name }} - {{ order.area_name }}</td>
                  <td>
                    {{ order.invoice_barcode }}
                  </td>
                  <td>
                    <button
                        type="button"
                        class="btn btn-dark"
                        @click="updateBarcode(order.id, order.to_aramex)"
                    >
                      Update
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: 'OrderTracking',

  data() {
    return {
      currentTab: 'allOrders', // Track the current tab
      allOrder: [],
      orderInWarehouse: [],
      orderOutWarehouse: [],
      completedOrders: [],
      message: '', // To display success or error messages
      isSuccess: true, // To determine the type of message (success or error)
      debounceTimeout: null,
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {

    orderLoad() {
      axiosInstance.get(`api/get-barcoded-invoices`).then(({data}) => {
        this.allOrder = data;
      }).catch(error => {
        console.error('Error loading orders:', error);
      });
    },

    updateBarcode(id, to_aramex) {
      axiosInstance.post(`api/update-barcoded-management-completed`, {
        id: id,
        to_aramex: to_aramex,
      }).then(() => {
        this.message = `Barcode for order #${id} updated successfully!`;
        this.isSuccess = true;
        this.orderLoad();
      }).catch(error => {
        console.error('Error updating barcode:', error);
        this.message = error.response?.data?.error || `Failed to update barcode for order #${id}.`;
        this.isSuccess = false;
      });
    },
  }
};
</script>

<style scoped>
.nav-link.active {
  background-color: #007bff;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
