<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Jarde Panel</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Jarde Name</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="jardes in jarde" v-bind:key="jardes.id">
              <th scope="row">{{ jardes.id }}</th>
              <td>{{ jardes.name }}</td>
              <td>
                <router-link :to="{ name: 'JardeExportData', params: { id: jardes.id } }"
                             class="btn btn-warning me-2"><i class="fa fa-eye"></i></router-link>

              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';


export default {

  data() {
    return {
      jarde: [],
    };
  },

  created() {
    this.attributeLoad();
  },

  methods: {
    attributeLoad() {
      var page = `api/get-jarde-export`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.jarde = data;
      });
    },

  },
};

</script>

<style scoped></style>