<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Orders Panel</h3>

            <table class="table w-100">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="order in allOrder" :key="order.id">
                <th scope="row">{{ order.id }}</th>
                <td>
                  <router-link :to="{ name: 'OrderCompletedInvoices', params: { id: order.id } }">
                    {{ order.name }}
                  </router-link>
                  </td>
              </tr>
              </tbody>
            </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {

  data() {
    return {
      allOrder: [],
    };
  },

  created() {
    this.orderLoad(); // Load orders from the server
  },

  methods: {
    orderLoad() {
      axiosInstance.get('api/aramex-delivery-invoices')
          .then(({data}) => {
            this.allOrder = data;
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

  },
};
</script>


<style scoped>
.nav-link.active {
  background-color: #007bff;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
</style>
