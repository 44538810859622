
<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div v-if="showZoomedImage" id="zoomedImageContainer">
            <div class="zoomedImageContent">
              <span class="close" @click="closeZoomedImage">&times;</span>
              <img :src="zoomedImageSrc" alt="Zoomed Image">
            </div>
          </div>

          <h3 class="mb-5">Product Detail</h3>

          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <div class="mb-3 my-3">
            <label for="sku-search">Search by SKU:</label>
            <input type="text" id="sku-search" v-model="searchQuery" @blur="filterProductsBySku" placeholder="Enter SKU">
          </div>

          <table class="table w-100">
            <thead>
            <tr>
              <th>Sku</th>
              <th>Title</th>
              <th>Image</th>
              <th>Sale Price</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in filteredProductDetails" :key="product.id">
              <td>{{ product.sku }}</td>
              <td>{{ product.title }}</td>
              <td><img @click="zoomImage(product.image)"
                       :src="'https://api.gocami.com/product_detail/' + product.image" width="100" />
              </td>
              <td v-if="product.sale_price">${{ product.sale_price.toFixed(2) }}</td>
              <td v-if="product.total_commission">{{ product.total_commission }}</td>
              <td v-if="product.affiliate_commission">{{ product.affiliate_commission }}</td>
              <td v-if="product.sub_affiliate_commission">{{ product.sub_affiliate_commission }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      allProductDetails: [],
      filteredProductDetails: [],
      currentPage: 1,
      selectedBrand: null,
      brands: [],
      showZoomedImage: false,
      zoomedImageSrc: '',
      searchQuery: ''
    };
  },

  created() {
    this.userLoad();
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    zoomImage(imageSrc) {
      this.zoomedImageSrc = 'https://api.gocami.com/product_detail/' + imageSrc;
      this.showZoomedImage = true;
    },

    closeZoomedImage() {
      this.showZoomedImage = false;
    },

    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.userLoad(this.selectedBrand);
      }
    },

    userLoad(brandId) {
      var nextPage = `api/get-product-commissions-management/${brandId}`;
      axiosInstance.get(nextPage).then(({ data }) => {
        this.allProductDetails = data.products.map(product => ({
          ...product,
          checked: false
        }));
        this.brands = data.brands;
        this.filteredProductDetails = this.allProductDetails; // Initialize with all products
      });
    },

    filterProductsBySku() {
      if (this.searchQuery.trim() === '') {
        this.filteredProductDetails = this.allProductDetails;
      } else {
        this.filteredProductDetails = this.allProductDetails.filter(product =>
            product.sku.includes(this.searchQuery.trim())
        );
      }
    },

    handleClickOutside(event) {
      const input = document.getElementById('sku-search');
      if (!input.contains(event.target)) {
        this.filterProductsBySku(); // Filter products when clicking outside the input
      }
    }
  }
}

</script>


<style scoped>
.link-to:hover {
  color: red;
}

.bg-danger {
  background-color: red;
}

.bg-warning {
  background-color: orange;
}

.bg-success {
  background-color: green;
}

/* New styles for zoomed image container */
#zoomedImageContainer {
  display: block;
  /* Add this line to make the container visible */
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.9); */
  overflow: auto;
}

.zoomedImageContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 999999 !important;
}

.zoomedImageContent img {
  z-index: 99999 !important;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #fff;
  /* Optional: Add a border around the image */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  /* Optional: Add a box shadow */
}

.close {
  color: red !important;
  position: absolute;
  top: 10px;
  right: 110px;
  font-size: 30px !important;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.close:hover {
  transform: scale(1.2);
  /* Optional: Increase the size of the close icon on hover */
}
</style>