<template>
  <div class="container">
    <h2>Product Commission Report</h2>
    <input
        v-model="sku"
        @input="searchProduct"
        placeholder="Search by SKU"
        type="text"
        class="form-control"
    />
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="error" class="error">{{ error }}</div>

    <div v-if="productData && !loading">
      <h3 class="mt-5">Current Product Data</h3>
      <ul>
        <li><strong>Sale Price:</strong> {{ productData.current.sale_price }}</li>
        <li><strong>Quantity:</strong> {{ productData.current.quantity }}</li>
        <li><strong>Total Commission:</strong> {{ productData.current.total_commission }}</li>
        <li><strong>Affiliate Commission:</strong> {{ productData.current.affiliate_commission }}</li>
        <li><strong>Sub Affiliate Commission:</strong> {{ productData.current.sub_affiliate_commission }}</li>
      </ul>

      <h3>Old Commission Data</h3>
      <table v-if="productData.old.length > 0" class="table">
        <thead>
        <tr>
          <th>Total Commission</th>
          <th>Affiliate Commission</th>
          <th>Sub Affiliate Commission</th>
          <th>Created At</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="old in productData.old" :key="old.id">
          <td>{{ old.total_commission }}</td>
          <td>{{ old.affiliate_commission }}</td>
          <td>{{ old.sub_affiliate_commission }}</td>
          <td>{{ formatDate(old.created_at) }}</td>
        </tr>
        </tbody>
      </table>
      <div v-else>No historical data available.</div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      sku: "",
      productData: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    async searchProduct() {
      if (this.sku === "") {
        this.productData = null;
        return;
      }

      this.loading = true;
      this.error = null;

      try {
        const response = await axiosInstance.get("api/product-commissions", {
          params: { sku: this.sku },
        });
        this.productData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.error = "Product not found";
        } else {
          this.error = "An error occurred while fetching data";
        }
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options).replace(",", "");
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.loading {
  font-size: 18px;
  font-weight: bold;
}

.error {
  color: red;
}

.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
